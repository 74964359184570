const baseStrings = {
	translation: {
		home: "Inicio",
		menu: "Menú",
		opportunity: "Especial",
		opportunityProducts: "Ofertas Especiales",
		payment: "Pago",
		back: "Atrás",
		yourName: "Su Nombre",
		roomNumber: "Número de Habitación",
		deliveryInfo: "Información de Entrega",
		yourDeliveryInfo: "Información de Entrega",
		paymentInfo: "Información de Pago",
		productTotal: "Producto Total",
		serviceFee: "Tarifa de Servicio",
		trayCharge: "Cargo por bandeja",

		total: "Total",
		online: "Online",
		card: "Tarjeta",
		myCart: "Mi Cesta",
		cash: "Efectivo",
		note: "Nota",
		delivery: "Entrega",
		payment: "Pago",
		orderNote: "Ingreser su nota de pedido",
		completeOrder: "Tramitar Pedido",
		enjoyYourMeal: "¡Buen provecho!",
		yourOrderReceived: "Su Pedido Recibido",
		// completeOrder: "Su Pedido Completo",
		hello: "¡Hola!",
		uuiError: "Escanear el ódigo QR para ver el menú",
		successful: "Exitoso",
		productAddToCart: "¡Producto agregado a la cesta!",
		addToBasket: "Añadir a la cesta",
		removeFromBasket: "Vaciar cesta",
		productTitle: "CUIDADO PERSONAL",
		itemNotFound: "Producto no encontrado",
		completeTheOrder: "Completar el pedido",
		selectThePayment: "Elegir el tipo de pago",
		paymentType: "Tipo de Pago",
		orderLimitAuthor: "Limite de pedido {{author}} {{currency}}",
		orderLimit: "Límite de pedido",
		dataNotFound: "Datos no encontrados",
		confirmOrder: "Tocar para completar el pedido",
		exchangeInfo: "Si pagas en moneda extranjera, te darán el cambio en liras turcas.",
		deliveryInfo:
			"Para que su pedido se entregue correctamente, asegúrese de escribir completamente su nombre y la información del número de habitación.",
		weather: "Clima",
		totalEuro: "Total (Euro)",
		totalDollar: "Total (Dólar)",
		totalTL: "Total (Lira Turca)",
		diss: "¡Descuento en todos los productos para hoy!",
		continue: "Continuar",
		accept: "Acepto el contrato de venta a distancia",
		done: "Su pedido se está preparando...",
		enjoyMeal: "Buen Provecho",
		dk: "Min (Minuto)",
		deliveryAndPayment: "Entrega y Pago",

		continue: "Continuar",
		cashCurrency: "Seleccione el tipo de moneda",
		other: "Otras categorías",

		// preparing: "Se está preparando su pedido Disfrute de su comida...",
		preparing: "¡Gracias! Hemos recibido tu pedido. Por favor, no olvides revisar tu bandeja de entrada para los detalles.",

		notValid: "Ingrese el nombre y el número de la habitación",

		searchHome: "Buscar ...",
		notfound: "Datos no encontrados",
		"back-to-menu": "Volver al menú",
		bankErrorTitle: "Pago fallido",
		bankErrorDescription: "Hay un problema con el pago. Verifique la información de su tarjeta e inténtelo nuevamente",
		minimumCart: "mínimo : {{author}}",
		modalLegalAgeTitle: "¿Tienes edad suficiente para comprar artículos con restricción de edad?",
		modalLegalAgeText:
			"Los artículos con restricción de edad (incluido el alcohol y el tabaco) no están a la venta a menores de 18 años. Es posible que deba proporcionar una identificación válida al recibir su pedido",
		modalLegalAcceptButton: "sí, tengo 18 años o más",
		cancel: "Cancelar",
		medicineTitle: "Los productos para aliviar el dolor tienen un límite de 2 por unidad",
		phoneNumber: "número de teléfono",
		phoneNumberDelivery: "Número de teléfono: Nuestro repartidor le llamará",
		phoneNumberCourier: "Número de teléfono: Nuestro mensajero le llamará",
		useDiscountCode: "Usa tu código de descuento",
		discount: "Descuento",
		discountAuthor: "Descuento ({{author}})",
		products: "Productos",
		append: "Agregar",
		code: "Código",
		checkAuthor: "verificar {{author}}",
		"discount-note": "Puedes consultar tu código de descuento desde esta sección y utilizarlo si es necesari",
		"discount-code-details":
			"El código que proporcionaste es {{code}}. El nombre de este código de descuento es {{name}} y tiene un valor de {{amount}}% de descuento",
		"back-to-menu": "Volver al menú",
		bankErrorTitle: "Pago fallido",
		bankErrorDescription: "Hay un problema con el pago. Verifique la información de su tarjeta e inténtelo nuevamente",
		minimumCart: "mínimo : {{author}}",
		orderNow: "Ordene ahora",
		AppointmentDaySelection: "Selección del día de la cita",
		email: "Correo electrónico",
		apply: "Aplicar",
		allergyText: `Los alimentos pueden contener o estar en contacto con alérgenos. Por favor, indique cualquier alergia alimentaria en las "Notas" al finalizar la compra`,
		notePlaceholder: "Los alimentos pueden contener o estar en contacto con alérgenos. ¡Por favor, indique cualquier alergia alimentaria!",
		orderNumberDoneAuthor: "Número de pedido: {{author}}",
	},
};

export const es = baseStrings;
