import "react-loading-skeleton/dist/skeleton.css";
import { React, useState } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "assets/css/bootstrap.min.css";
import "assets/main.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import CartProvider from "_context/shoppingCart/cartProvider";
import DeliveryProvider from "_context/delivery/deliveryProvider";
import PaymentProvider from "_context/payment/paymentProvider";
import UuidProvider from "_context/uuid/uuidProvider";
import CategoryProvider from "_context/category/provider";
import UtmSourceProvider from "_context/utmSource/uuidProvider";
import DiscountProvider from "_context/discount/discountProvider";

import Snowfall from "react-snowfall";
import "swiper/css";
import "swiper/css/pagination";
import OrderProvider from "_context/order/prderProvider";

// import snow from "./../src/assets/snow.png";
// import snow2 from "./../src/assets/snow.png";
// import snowflake from "./../src/assets/snowflake.png";

// import 'bootstrap/dist/css/bootstrap.min.css';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

// const snowImages = [snowflake];
// console.log("images", images);
// var snowflake1 = new Image();
// snowflake1.src = "./../src/assets/snow.png";
// const snowflake2 = document.createElement("img");
// snowflake2.src = "./../src/assets/snow.png";

// const images = [snowflake1, snowflake2];

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<UtmSourceProvider>
				<UuidProvider>
					<CartProvider>
						<DiscountProvider>
							<DeliveryProvider>
								<PaymentProvider>
									<OrderProvider>
										<CategoryProvider>
											<I18nextProvider i18n={i18n}>
												<App />
											</I18nextProvider>
										</CategoryProvider>
									</OrderProvider>
								</PaymentProvider>
							</DeliveryProvider>
						</DiscountProvider>
					</CartProvider>
				</UuidProvider>
			</UtmSourceProvider>

			{/* <ReactQueryDevtools initialIsOpen /> */}
		</QueryClientProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
