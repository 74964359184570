import React, { createContext, useState, useContext } from "react";
import { useEffect } from "react";

const OrderContext = createContext();
export const useOrder = () => useContext(OrderContext);

const name = "order";

function getInitialState() {
	const data = localStorage.getItem(name);
	return data ? JSON.parse(data) : {};
	//  "e3a427f6-7821-4f2b-be22-012effb8de6b"
}

export default function OrderProvider({ children }) {
	const [data, setData] = useState(getInitialState);

	useEffect(() => {
		localStorage.setItem(name, JSON.stringify(data));
	}, [data]);

	const initial = () => setData({});

	return <OrderContext.Provider value={{ data, setData, initial }}>{children}</OrderContext.Provider>;
}
