const baseStrings = {
	translation: {
		home: " Page d'accueil ",
		menu: " Menu ",
		opportunity: " Spécial ",
		opportunityProducts: " Offres spéciales ",
		payment: " Paiement ",
		back: " Retour ",
		yourName: " Nom et Prénom ",
		roomNumber: " Numéro de chambre ",
		deliveryInfo: " Informations sur les invités ",
		yourDeliveryInfo: " Informations sur les invités ",
		paymentInfo: " Informations sur le paiement ",
		productTotal: " Total du produit ",
		serviceFee: " Frais de service ",
		total: " Montant total ",
		online: " Payer en ligne ",
		card: " Paiement à la livraison par carte ",
		myCart: " Mon panier ",
		cash: " Cash ",
		note: " Note ",
		delivery: " Livraison ",
		payment: " Paiement ",
		orderNote:
			" Veuillez nous informer si vous avez des allergies alimentaires, des besoins diététiques particuliers ou toute autre demande spécifique à l'intention de notre équipe. ",
		completeOrder: " Commande complète ",
		enjoyYourMeal: " Bon appétit !",
		yourOrderReceived: " Votre commande a été reçue ",
		// completeOrder: " Votre commande est terminée ",
		hello: " Bonjour. ",
		uuiError: " Vous pouvez participer en scannant simplement le code QR.",
		successful: " Réussi ",
		productAddToCart: " Produit ajouté au panier!",
		addToBasket: " Ajouter au panier ",
		removeFromBasket: " Enlever du panier ",
		productTitle: " SOINS PERSONNELS ",
		itemNotFound: " Article introuvable ",
		completeTheOrder: " Veuillez compléter la commande ",
		selectThePayment: " Veuillez sélectionner le type de paiement ",
		paymentType: " Mode de paiement ",
		orderLimitAuthor: " Limite de l'ordre {{auteur}} {{devise}}",
		orderLimit: " Limite de commande ",
		dataNotFound: " Aucune donnée n'a été trouvée ",
		confirmOrder: " Tapez pour terminer la commande ",
		exchangeInfo: " Si vous payez en devises étrangères, la monnaie vous sera rendue en lires turques. ",
		deliveryInfo: " Veillez à bien indiquer votre nom et votre numéro de chambre afin que votre commande soit livrée correctement.",
		weather: " Météo ",

		totalEuro: " Montant total (euros)",
		totalDollar: " Montant total (dollars)",
		totalTL: " Montant total (TL)",
		diss: " Remise sur tous les produits spéciaux pour aujourd'hui!",

		continue: " Continuez ",
		cashCurrency: " Sélectionner la devise ",
		other: " Autres catégories ",

		// preparing: " Votre commande est en cours de préparation Savourez votre repas...",
		preparing: "Merci ! Nous avons bien reçu votre commande. N'oubliez pas de vérifier votre boîte de réception pour les détails.",

		notValid: " Saisir le nom complet et le numéro de chambre ",

		deviceError:
			" La taille d'écran d'une tablette ou d'un téléphone portable est autorisée. La taille de page autorisée est inférieure à 768 pixels. ",
		searchHome: " Search ...",
		notfound: " Données non trouvées ",
		"back-to-menu": "Retour au menu",
		bankErrorTitle: "Le paiement a échoué",
		bankErrorDescription: "Il y a un problème avec le paiement. Veuillez vérifier les informations de votre carte et réessayer",
		minimumCart: "le minimum : {{author}}",
		modalLegalAgeTitle: "Êtes-vous assez vieux pour acheter des articles soumis à une limite d'âge ?",
		modalLegalAgeText:
			"Les articles soumis à une limite d'âge (y compris l'alcool et le tabac) ne sont pas à vendre à des personnes de moins de 18 ans. Vous devrez peut-être fournir une pièce d'identité valide à la réception de votre commande",
		modalLegalAcceptButton: "oui, j'ai 18 ans ou plus",
		cancel: "Annuler",
		medicineTitle: "les produits anti-douleur ont une limite de 2 par unité",
		phoneNumber: "numéro de téléphone",
		phoneNumberDelivery: "Numéro de téléphone : Notre livraison vous appellera",
		phoneNumberCourier: "Numéro de téléphone : Notre coursier vous appellera",
		useDiscountCode: "Utilisez votre code de réduction",
		discount: "Remise",
		discountAuthor: "Remise ({{author}})",
		products: "Produits",
		append: "Ajouter",
		code: "Code",
		checkAuthor: "vérifier {{author}}",
		"discount-note": "Vous pouvez vérifier votre code de réduction dans cette section et l'utiliser si nécessaire",
		"discount-code-details":
			"Le code que vous avez fourni est {{code}}. Le nom de ce code de réduction est {{name}} et il a une valeur de {{amount}} % de réduction",
		"back-to-menu": "Retour au menu",
		bankErrorTitle: "Le paiement a échoué",
		bankErrorDescription: "Il y a un problème avec le paiement. Veuillez vérifier les informations de votre carte et réessayer",
		minimumCart: "le minimum : {{author}}",
		orderNow: "Commandez maintenant",
		AppointmentDaySelection: "Sélection du jour du rendez-vous",
		email: "Courriel",
		apply: "Appliquer",
		allergyText: `Les aliments peuvent contenir ou entrer en contact avec des allergènes. Veuillez indiquer toute allergie alimentaire dans les "Notes" lors de la commande`,
		notePlaceholder: "Les aliments peuvent contenir ou entrer en contact avec des allergènes. Veuillez indiquer toute allergie alimentaire !",
		orderNumberDoneAuthor: "Numéro de commande: {{author}}",
	},
};

export const fr = baseStrings;
